<template>
    <ion-app mode="ios">
        <template v-if="environment != 'production'">
            <div :class="{'environment-banner': true, 'environment-banner-beta': environment == 'beta'}">{{ environment.toUpperCase() }}</div>
        </template>

        <ion-router-outlet />

        <toast-container></toast-container>
    </ion-app>
</template>

<script lang="ts">
    import { IonApp, IonRouterOutlet, getPlatforms, useKeyboard } from '@ionic/vue';
    import { defineComponent, watch } from 'vue';
    import { PushNotifications } from '@capacitor/push-notifications';
    import { isPushAvailable, initPushNotifications, getDeviceId } from './library/push-notifications';
    import { App } from '@capacitor/app';

    import { Storage } from '@ionic/storage';

    import { config } from './config';

    import ToastContainer from './components/Toast/ToastContainer.vue';

    const ionicStore = new Storage();
    ionicStore.create();

    export default defineComponent({
        name: 'App',
        
        components: {
            IonApp,
            IonRouterOutlet,
            ToastContainer
        },

        computed: {
            environment() {
                return config.ENVIRONMENT;
            }
        },

        setup() {
            const { isOpen, keyboardHeight } = useKeyboard();

            watch(keyboardHeight, () => {
                if (isOpen.value) {
                    document.body.classList.add('keyboard-open');
                } else {
                    document.body.classList.remove('keyboard-open');
                }
            });
        },

        unmonuted() {
            App.removeAllListeners();
        },

        methods: {
            syncPushNotificationPermissions() {
                PushNotifications.checkPermissions().then(async currentPermission => {
                    const hasPermission = currentPermission.receive === 'granted';
                    const deviceId = await getDeviceId();
                    console.debug('Updating device push permissions', {
                        deviceId: deviceId,
                        hasPermission: hasPermission
                    });

                    if (deviceId) {
                        this.$store.dispatch('api/updateDevicePermissions', {
                            deviceId: deviceId,
                            notificationPermission: hasPermission
                        });
                    }

                });
            },

            compatibiltyCheck() {
                this.$store.dispatch('compatibility/checkCompatibility');
            },

            async updateTimezone() {
                try {
                    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                    console.debug('Detected timezone:', timezone);

                    const athleteProfile = await this.$store.dispatch('athlete/getProfile', {
                        athlete_id: this.$store.state.api.identity.athleteId
                    });

                    if (athleteProfile && athleteProfile.Preferences && athleteProfile.Preferences.TimeZoneInfoId === 'UTC' && timezone != 'UTC') {
                        console.debug('Updating athletes timezone', timezone);
                        this.$store.dispatch('athlete/updateProfile', { 
                            athlete_id: this.$store.state.api.identity.athleteId, data: { 
                                Preferences: { 
                                    TimeZoneInfoId: timezone 
                                } 
                            } 
                        });
                    }
                } catch (err) {
                    console.error('Failed to update timezone', err);
                }
            }
        },

        async mounted() {
            // force dark mode
            document.body.classList.add('dark');
            let platforms = getPlatforms();
            for (let i = 0; i < platforms.length; i++) {
                document.body.classList.add('platform-' + platforms[i]);
            }

            // initialize push notification hooks globally
            initPushNotifications(this.$store);

            App.addListener('appStateChange', state => {
                if (state.isActive) {
                    // redirect to dashboard
                    console.debug('App is active now');
                    const appInactiveTimestamp = localStorage.getItem('app_inactive_timestamp');
                    console.debug('App inactive timestamp', appInactiveTimestamp);
                    if (appInactiveTimestamp) {
                        const inactiveDuration = Date.now() - parseInt(appInactiveTimestamp);
                        console.debug('App inactive duration', inactiveDuration);
                        const diff = 1000 * 60 * 60 * 3; // 3 hours
                        if (inactiveDuration >= diff) {
                            this.$router.push('/app/dashboard');
                        }
                    }
                } else {
                    // store timestamp when app goes inactive
                    localStorage.setItem('app_inactive_timestamp', Date.now().toString());
                    console.debug('App is inactive now');
                }
            });

            // (re)register device if permission was granted and user is logged in
            const currentUser = await this.$store.dispatch('auth/getCurrentUser');
            if (currentUser) {
                if (isPushAvailable()) {
                    try {
                        const currentPermission = await PushNotifications.checkPermissions();
                        if (currentPermission.receive === 'granted') {
                            PushNotifications.register();
                        }
                    } catch (err) {
                        console.info('Push notifications are not supported on this device', err);
                    }
    
                    App.addListener('appStateChange', state => {
                        if (state.isActive) {
                            this.syncPushNotificationPermissions();
                        }
                    });
    
                    this.syncPushNotificationPermissions();
                }

                this.compatibiltyCheck();

                this.updateTimezone();
            }
        }
    });
</script>