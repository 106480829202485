import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'
import App from './App.vue'
import router from './router';

import { IonicVue } from '@ionic/vue';

import './library/validation';

import store from './store';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/scaffolding.css';
import './theme/main.js';


/* Translations & Locales */
import i18nEN from './locale/en';

// Sentry
import './sentry';

// Vue Scroll Picker
import VueScrollPicker from 'vue-scroll-picker'

// PWA Elements
import { defineCustomElements } from '@ionic/pwa-elements/loader';

// Import Lottie
import Vue3Lottie from 'vue3-lottie';

const i18n = createI18n({
  locale: 'en',
  messages: {
    en: i18nEN
  }
});


const app = createApp(App)
  .use(i18n)
  .use(IonicVue)
  .use(store)
  .use(router)
  .use(Vue3Lottie)
  .use(VueScrollPicker);

defineCustomElements(window);
  
router.isReady().then(() => {
  app.mount('#app');
});