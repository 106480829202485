import { TRIQ_API_URL } from '../config';
import axios from 'axios';

const API_ENDPOINT_TRAININGPLAN = '/api/v1/app/training-plan';
const API_ENDPOINT_TRAININGPLAN_VALIDATE = '/api/v1/app/validate-training-plan';
const API_ENDPOINT_TRAININGPLAN_UPDATE = '/api/v1/app/save-edited-training-plan';

export default {
    namespaced: true,

    state: {

    },

    actions: {
        getTrainingPlan({ dispatch }, params) {
            return dispatch('auth/request', config => {
                return axios.get(TRIQ_API_URL + API_ENDPOINT_TRAININGPLAN, { params: params, headers: config.headers });
            }, { root: true });
        },

        validate({ dispatch }, payload) {
            return dispatch('auth/request', config => {
                return axios.post(TRIQ_API_URL + API_ENDPOINT_TRAININGPLAN_VALIDATE, payload, { headers: config.headers });
            }, { root: true });
        },

        update({ dispatch }, payload) {
            return dispatch('auth/request', config => {
                return axios.post(TRIQ_API_URL + API_ENDPOINT_TRAININGPLAN_UPDATE, payload, { headers: config.headers });
            }, { root: true });
        }
    }
};